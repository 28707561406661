const sms_expirations_data = [
  {
    nameVal: "1分钟",
    num: 60
  },
  {
    nameVal: "2分钟",
    num: 120
  },
  {
    nameVal: "3分钟",
    num: 180
  },
  {
    nameVal: "4分钟",
    num: 240
  },
  {
    nameVal: "5分钟",
    num: 300
  }
];

export { sms_expirations_data };
